import "../scss/home.scss"

$(document).ready(function(){

    $('.carousel').carousel();

    setTransitionVisibility(1000);
    setScrollTransitionVisibility();
});

function setTransitionVisibility(timeBetweenElements){
    let time = 0;
    $('.beforeTransitionVisibility').each(function(){
        let jQueryElement = $(this);
        setTimeout( function(){ changeClass(jQueryElement, 'beforeTransitionVisibility', 'transitionVisibility-2'); }, time);

        time += timeBetweenElements;
    })
}

function changeClass(jQueryElement ,toRemove, toAdd){
    jQueryElement.removeClass(toRemove);
    jQueryElement.addClass(toAdd);
}


function setScrollTransitionVisibility(){
    $(window).on('scroll', function() {
        $('.beforeTransitionVisibilityScroll').each(function(){
            let jQueryElement = $(this);
            let anchor_offset = jQueryElement.offset().top;
            let limitBottom = $(window).scrollTop() + $(window).height();

            if ( limitBottom > anchor_offset ){
                changeClass(jQueryElement, 'beforeTransitionVisibilityScroll', 'transitionVisibility-2')
            }
        });
    });
}